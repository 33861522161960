<template>
  <div class="user-actions" :id="this.params.data.idDoc">
    <router-link :to="{name:'superadmin.edit', params: {idDoc: this.params.data.idDoc} }">
      <button type="button" :attr="this.params.data.idDoc" data-action-type="edit" class="btn btn-default text-success">
        <i class="ri-edit-2-line"></i>Edit
      </button>
    </router-link>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  mixins: [
    {
      data () {
        return {
          modalShow: false
        }
      },
      methods: { }
    }
  ]
})
</script>
